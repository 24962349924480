<template>
  <div>
    <content-background-cover
      background-color='#FDFCFD'
      class='z-0'
      :top-offset='0' />
    <korean-tour-hero />
    <korean-tour-videos />
    <social-media-style-images />
  </div>
</template>

<script>
import ContentBackgroundCover      from '@/components/ContentBackgroundCover.vue'
import KoreanTourHero              from '@/components/gbcc2022/KoreanTourHero.vue'
import KoreanTourVideos            from '@/components/gbcc2022/KoreanTourVideos.vue'
import SocialMediaStyleImages      from '@/components/social-media-style/SocialMediaStyleImages.vue'

export default {
  name: 'KoreanTour',
  components: {
    ContentBackgroundCover,
    KoreanTourHero,
    KoreanTourVideos,
    SocialMediaStyleImages,
  },
}
</script>
