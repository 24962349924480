<template>
  <div 
    class='relative flex flex-col justify-between items-center pt-48 pb-36' 
    style='height: 640px;'>
    <content-background-cover
      background-color='#FAFAFA'
      class='z-0'
      :top-offset='0'
      :height='640'
      :style='heroBackgroundStyle' />
    <h1 class='text-2xl lg:text-6xl font-bold text-white uppercase'>Virtual Korea Tourism</h1>
    <a
      class='flex flex-row items-center justify-center gap-x-2 text-normal text-white uppercase rounded shadow-md py-4 px-24'
      style='background-color: #8D1C5D;'
      href="#" v-scroll-to="'social-media-style-images'">
      Join Event
      <arrow-narrow-right-icon class='h-5' />
    </a>
  </div>
</template>

<script>
import { ArrowNarrowRightIcon } from 'vue-tabler-icons'
import ContentBackgroundCover from '@/components/ContentBackgroundCover.vue'


export default {
  name: 'KoreanTourHero',
  components: {
    ContentBackgroundCover,
    ArrowNarrowRightIcon,
  },
  computed: {
    heroBackgroundStyle () {
      return `background-image: url(https://d3r2ol85dktaw0.cloudfront.net/conferences/gbcc2022/gbcc_korean_tour_hero_bg.png); background-repeat: no-repeat; background-position: 0 0;background-size: cover;`
    },
  },
}
</script>
