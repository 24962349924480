<template>
  <div class='my-8 px-16 py-16 bg-white'>
    <div class='text-center'>
      <img 
        src='https://d3r2ol85dktaw0.cloudfront.net/conferences/gbcc2022/360_icon.jpeg'
        class='inline-block h-16 mt-8' />
      <h1 class='my-8 text-xl lg:text-3xl font-bold text-gray-900'>Welcome to Virtual Korea Tourism!</h1>
      <p 
        class='text-lg lg:text-xl max-w-2xl mx-auto my-10 text-gray-700'
        style='line-height: 2rem;'>
        Please enjoy our library of 360 Virtual Reality videos and explore a range of 
        historic and modern Korean locations, all before visiting in person!
      </p>
      <p 
        class='text-lg lg:text-xl max-w-2xl mx-auto my-10 text-gray-700'
        style='line-height: 2rem;'>
        Click the videos below to get started!
      </p>
      <p 
        class='text-lg lg:text-xl max-w-2xl mx-auto my-10 text-gray-700'
        style='line-height: 2rem;'>
        Use the arrow keys on your keyboard or drag the video screen with your mouse to look around!
      </p>
      <p 
        class='text-lg lg:text-xl max-w-2xl mx-auto my-10 text-gray-700'
        style='line-height: 2rem;'>
        And don’t forget to participate in our <a href='' class='inline-block underline bg-transparent' :style='themeTextColorStyle'>promotional event</a> to win prizes!
      </p>
    </div>
    <div class='mt-12 flex flex-row flex-wrap justify-center items-center gap-x-4 gap-y-12'>
      <div v-for='video in videos'
        :key='`korean-tour-video-${video.title}`'
        class='w-64 border border-gray-200 hover:shadow-md cursor-pointer'
        @click='showThisVideo(video)'>
        <div class='w-64 h-60'>
          <img :src='awsImageForTourism(video.previewThumbnailFilename)' class='w-72 h-60' />
        </div>
        <div class='px-4 py-8 flex flex-col justify-between items-start h-40'>
          <div>
            <h3 class='text-xs uppercase' :style='themeTextColorStyle'>{{video.category}}</h3>
            <h1 class='text-xl text-gray-800'>{{video.title}}</h1>
          </div>
          <h5 class='text-sm text-gray-500'>#{{video.tag}}</h5>
        </div>
      </div>
    </div>
    <modal
      name='tourism-video'
      :adaptive='true'
      @close='resetSelectedVideo'
      classes='korean-tour-image-modal'>
      <div class='h-full bg-gray-900 overflow-y-auto relative'>
        <button class='z-30 absolute top-3 right-3' @click="$modal.hide('tourism-video')">
          <x-icon class='text-white' />
        </button>
        <div class='h-full flex flex-col justify-center'>
          <vod-content-vimeo
            :showing-content='selectedVideoContent'
            :autoplay='false'
            :mute='false' />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { XIcon } from 'vue-tabler-icons'
import VodContentVimeo from '@/components/contents/VodContentVimeo.vue'

export default {
  name: 'KoreanTourVideos',
  components: {
    VodContentVimeo,
    XIcon,
  },
  data () {
    return {
      videos: [
        {
          title: 'Kukkiwon',
          category: 'Play Together in Korea',
          tag: 'Seoul',
          videoLink: 'https://vimeo.com/680796175',
          previewThumbnailFilename: 'korea_kukkiwon.png',
        },
        {
          title: '1 Million Dance Team',
          category: 'Play Together in Korea',
          tag: 'Seoul',
          videoLink: 'https://vimeo.com/684081585',
          previewThumbnailFilename: 'korea_1milliondanceteam.png',
        },
        {
          title: 'Kyonggi-Do',
          category: 'Reboot Your Travel in Korea',
          tag: 'Geongi-do',
          videoLink: 'https://vimeo.com/684081604',
          previewThumbnailFilename: 'korea_kyonggi.png',
        },
        {
          title: 'Gyeoungju',
          category: 'Reboot Your Travel in Korea',
          tag: 'Gyeoungju',
          videoLink: 'https://vimeo.com/684081633',
          previewThumbnailFilename: 'korea_gyeoungju.png',
        },
               {
          title: 'Gyeongsangnam-do',
          category: 'Reboot Your Travel in Korea',
          tag: 'Gyeongsangnam-do',
          videoLink: 'https://vimeo.com/684081618',
          previewThumbnailFilename: 'korea_gyeongsangnam.png',
        },
               {
          title: 'Gyeongsangbuk-do',
          category: 'Reboot Your Travel in Korea',
          tag: 'Gyeongsangbuk-do',
          videoLink: 'https://vimeo.com/684081642',
          previewThumbnailFilename: 'korea_gyeongsangbuk.png',
        },
               {
          title: 'Chungchungbuk-do',
          category: 'Reboot Your Travel in Korea',
          tag: 'Chungchungbuk-do',
          videoLink: 'https://vimeo.com/684081588',
          previewThumbnailFilename: 'korea_chungchungbuk.png',
        },
               {
          title: 'Ulsan',
          category: 'Reboot Your Travel in Korea',
          tag: 'Ulsan',
          videoLink: 'https://vimeo.com/684081610',
          previewThumbnailFilename: 'korea_ulsan.png',
        },
      ],
      selectedVideoLink: '',
    }
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    themeTextColorStyle () {
      let color = (this.eventMainThemeColor) ? this.eventMainThemeColor : '#333' 
      return `color: ${color};`
    },
    selectedVideoContent () {
      return {
        data: {
          sourceUrl: this.selectedVideoLink
        }
      }
    },
  },
  methods: {
    awsImageForTourism (filename) {
      return `https://d3r2ol85dktaw0.cloudfront.net/conferences/gbcc2022/tourism/${filename}`
    },
    resetSelectedVideo () {
      this.selectedVideoLink = ''
    },
    showThisVideo (video) {
      this.selectedVideoLink = video.videoLink
      this.$modal.show('tourism-video')
    },
  },
}
</script>
